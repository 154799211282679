.table-row-format tbody tr td:first-child {
  border-left-style: solid;
}
.table-row-format tbody tr td:last-child {
  border-right-style: solid;
}

.table-row-format thead tr th {
  box-shadow:
    inset 0 0px 0 #4e4e4e,
    inset 0 -2px 0 #4e4e4e;
  padding: 5px 0;
}

.accordion-panel-close {
  overflow: hidden;
  max-height: 0;
  transition: max-height 1.5s ease-in;
}

.accordion-panel {
  overflow: hidden;
  /* enable horizontal scrollbar if necessary */
  overflow-x: auto;
  max-height: 0;
  transition: max-height 1s ease-in;
}

/* horly Chart */
.mt-10 > div > div > div > div > svg > g:nth-child(6) > text {
  cursor: pointer !important;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  font-family: 'MONTSERRAT' !important;
  font-size: 12px !important;
  fill: #4e4e4e !important;
}

/* weekly Chart */
.mr-7 > div > div > div > div > svg > g:nth-child(6) > text {
  font-family: 'MONTSERRAT' !important;
  font-size: 12px !important;
  fill: #4e4e4e !important;
}

.-my-8 > div > div > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(4) > g > text {
  cursor: pointer !important;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  font-family: 'MONTSERRAT' !important;
  font-size: 14px !important;
  fill: #4e4e4e !important;
}

.min-w-3xl {
  min-width: 48rem;
}

.min-w-4xl {
  min-width: 56rem;
}

.min-w-5xl {
  min-width: 64rem;
}

.min-w-6xl {
  min-width: 72rem;
}

.min-w-7xl {
  min-width: 80rem;
}

.modal-border-shadow {
  box-shadow: 8px 10px 8px #d3d3d3;
}

.table-overflow {
  height: calc(100vh - 10rem);
  width: calc(100vw - 19.5rem);
  overflow-x: scroll;
}
